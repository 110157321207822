$site-domain: "";
@import "variables";
@import "button";
@import "ui";
@import "fonts";
@import "slick-carousel/slick/slick.css";
@import "aos/dist/aos.css";

.spinner-h {
    position: fixed;
    background: transparent;
    background: #fbf9f7;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    transition: all 0.5s;
}

.box {
    position: relative;
    background: var(--tertiary-bg);
    color: var(--main-color);
    border: .1rem solid var(--main-border-color);
    margin: 0 0 3rem;

    .box-heading {
        padding: .5rem 2rem;
    }

    .box-content {
        padding: 2rem;

        >*:last-child {
            margin-bottom: 0;
        }
    }

    .box-footer {
        padding: .5rem 2rem;
    }

    &.box02 {
        border: 0;
        border-radius: 2rem;
        box-shadow: var(--main-box-shadow);
    }
}


.error__page {
    background: #F1F1F3;
    main {
        // min-height: calc(100vh - 8rem);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.error__page-content {
    padding: 0 0 23rem;

    &>img,
    &>svg {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 2rem;
    }

    h1 {
        font-size: 7rem;
        line-height: normal;
        font-weight: 500;
        margin-bottom: 3rem;
    }

    *:last-child {
        margin-bottom: 0;
    }
}

.page-notfound-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5rem;
    padding: 10rem 0;

    &>img,
    &>svg {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 2rem;
    }

    h1 {
        font-size: 7rem;
        line-height: normal;
        font-weight: 500;
        margin-bottom: 2rem;
    }

    *:last-child {
        margin-bottom: 0;
    }
}


/* contactus */
.contactus__page main {
    padding-bottom: 0;
}

.contactus{
  padding: 3rem 0 8rem;

  h2{
    margin-bottom: 3rem;
  }

}
.contactus-details {
    
    .items {
        display: flex;
        gap: 3rem;

        .item {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 3rem;
            background: var(--tertiary-bg);
            border-radius: 2rem;
            padding: 3rem;

            .text {
                flex: 1;
            }

            *[class*=icon-] {
                display: inline-block;
                vertical-align: top;
                font-size: 4.4rem;
                line-height: 8rem;
                width: 8rem;
                height: 8rem;
                border-radius: 50%;
                color: var(--tertiary-color);
        background: var(--accent-color);
                text-align: center;
            }

            h2 {
                margin-bottom: 1rem;
            }

            a {
                color: var(--main-color);
                text-decoration: none;

                &:hover {
                    color: var(--accent2-color);
                }
            }
        }
    }

    .social {
        text-align: inherit;
    }
}

.contactus-banner {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        background: #111D25 url(#{$site-domain}/Media/Image/bg-contactus-banner.jpg) 50% 50% / cover;
    }

    .main-holder {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 49rem;
        text-align: center;
        color: var(--tertiary-color);

        .text {
            h2 {
                color: var(--tertiary-color);
                margin-bottom: 2.4rem;
            }
        }

        .social a {
            color: var(--tertiary-color);

            &:hover {
                color: var(--accent2-color);
            }
        }
    }
}

.rtl-page .contactus-banner:before {
    transform: scaleX(-1);
}

@media only screen and (max-width: 1200px) {
    .page-notfound-content {
        flex-direction: column;
        gap: 3rem;
    }

  .contactus-details {
        .items {
            .item {
                flex-direction: column;
                align-items: center;

                .text {
                    text-align: center;
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
  .contactus-details {
        .items {
      flex-direction: column;
      gap: 2rem;

      .item {
                flex-direction: row;
                padding: 2rem;
                align-items: flex-start;

                .text {
                    text-align: start;
                }
            }
    }
  }
}

@media only screen and (max-width: 767px) {

    .error__page-content {
        padding: 0 0 30rem;

        h1 {
            font-size: 3rem;
            margin-bottom: 2.5rem;
        }
    }

    .page-notfound-content {
        padding: 16rem 0;

        h1 {
            font-size: 3rem;
        }
    }
  .contactus{
    flex-direction: column;
    gap: 4rem;
    padding: 1rem 0 4rem;
  }
    .contactus-details {
        .items {
            flex-direction: column;
      gap: 2rem;

            .item {
                flex-direction: row;
                padding: 2rem;
                align-items: flex-start;

                .text {
                    text-align: start;
                }
            }
        }
    }

    .contactus-banner {
        &:before {
            background: #111D25 url(#{$site-domain}/Media/Image/bg-contactus-banner-sm.jpg) 50% 50% / cover;
        }

        .main-holder {
            min-height: 47.6rem;
      .text{
        h2{
          br{display: none;}
        }
      }
        }
    }
}

[class*=content-section] {
    position: relative;
    padding: 10rem 0;
}
.section-heading{
    margin-bottom: 6rem;
    *:last-child{margin-bottom: 0;}
}
@media only screen and (max-width: 767px) {
    [class*=content-section] {
        padding: 4rem 0;
    }
    .section-heading{
        margin-bottom: 3rem;
    }
}

.content-section01 {
    .section-heading{
        max-width: 117rem;
        margin-left: auto;
        margin-right: auto;
    }
    .items{
        display: flex;
        gap: 3rem;
        .item{
            display: flex;
            position: relative;
            padding: 3rem;
            flex: 1;
            gap: 3rem;
            margin: 0;

            *[class*=icon-]{
                display: inline-block;
                vertical-align: top;
                font-size: 4.4rem;
                line-height: 8rem;
                width: 8rem;
                height: 8rem;
                border-radius: 50%;
        background: var(--accent-color);
                color: var(--tertiary-color);
                text-align: center;
            }
            .text{
                flex: 1;
                h2{
                    margin-bottom: 1rem;
                } 
                *:last-child{margin-bottom: 0;}              
            }
        }
    }
}
@media only screen and (max-width: 1200px) {
    .content-section01{
        .item {
            flex-direction: column;
            align-items: center;

            .text {
                text-align: center;
            }
        }
    }
}
@media only screen and (max-width: 991px) {
    .content-section01{
        .items{
            flex-wrap: wrap;
            gap: 2rem;
            .item{
                padding: 2rem;
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .content-section01{
        .items {
            flex-direction: column;

            .item {
                flex-direction: row;
                padding: 2rem;
                align-items: flex-start;
                *[class*=icon-]{
                    margin: 0;
                }

                .text {
                    text-align: start;
                }
            }
        }
    }
}

.content-section02{
    background: var(--secondary-bg);
    color:var(--quarter-color);
    h2,
    h3{
        color: var(--fifth-color);
    }

    .row{
        --bs-gutter-x: 4rem;

    }
    .image{
        width: 50vw;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .text{
        padding-inline-end: 8.5rem;
        
        .frame{
            position: absolute;
            top: 10rem;
            right: calc(50% - 705px);
            width: 60rem;

            display: flex;
            gap: 3rem;
            padding: 4rem 0;
            border: 1px solid var(--secondary-border-color);
            border-width: .1rem 0;
            align-items: center;
            font-size: 2.4rem;
            font-weight: 700;
            line-height: 150%; 
            color: var(--fifth-color);
            margin-bottom: 6rem;

            *[class*=icon-]{
                display: inline-block;
                vertical-align: top;
                font-size: 4.4rem;
                line-height: 8rem;
                font-weight: 400;
                width: 8rem;
                height: 8rem;
                border-radius: 50%;
                color: var(--tertiary-color);
                text-align: center;
            }
            >div{
                flex: 1;
                *:last-child{margin-bottom: 0;}
            }
        }
        .items{
      display: flex;
      flex-direction: column;
      gap: 5rem;
      .item{
        display: flex;
        gap: 3rem;
        *[class*=icon-]{
          display: inline-block;
          vertical-align: top;
          font-size: 6rem;
          line-height: 6rem;
          color: var(--accent-color);
        }
        h3{
          margin-bottom: 1rem;
        }
        div{
          >*:last-child{margin-bottom: 0;}
        }
      }
    }
    }
}
.rtl-page .content-section02 .text .frame{
  right: auto;
  left: calc(50% - 705px);
}
.rtl-page .content-section02 .image{
    right: auto;
    left: 0;
}
@media only screen and (max-width: 1450px) {
  .content-section02{
    .text{
      .frame{
        right: 2rem;
        width: calc(50% - 4rem);
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
    .content-section02{
        .text{
            padding-inline-end: 0;
            .cols{
                --bs-gutter-x: 4rem;
            }
        }
    }
}
@media only screen and (max-width: 991px) {
    .content-section02{
        padding: 0;
        .section-heading{
            margin-bottom: 4rem;
            text-align: center;
        }
        .image{
            width: auto;
            margin: 0 -2rem;
            text-align: center;
            position: relative;
            padding: 0 0 83.33333333333333vw;
        }
        .text{
            padding: 4rem 0 4rem;
            width: 100%;
      .frame{
        position: static;
        width: auto;
      }
        }
    }
}
@media only screen and (max-width: 767px) {
    .content-section02{
        .text{
            .frame{
                padding: 2rem 0;
                font-size: 2rem;
                margin-bottom: 4rem;
        
        *[class*="icon-"]{
          align-self: flex-start
        }
            }
            .cols{
                flex-direction: column;
                gap: 4rem;
                .logo{
                    margin: 0 0 3rem;
                }
                .btn-hold{
                    padding-top: 0;
                }
            }
        }
    }
}

.content-section03{
    background: var(--fifth-bg);
    color:var(--quarter-color);
    h2,
    h3{
        color: var(--fifth-color);
    }
    .items{
        display: flex;
        gap: 3rem;
    margin-bottom: -.5rem;
        .item{
      flex: 1;
      display: flex;
      flex-direction: column;
            border: 1px solid var(--secondary-border-color);
            border-radius: 2rem;
            padding: 4rem;
            text-align: center;
            transition: all 0.8s ease;
      > div{
        flex: 1;
        >*[class*=icon-]{
          display: inline-block;
          vertical-align: top;
          font-size: 6rem;
          margin-bottom: 2rem;
          color: var(--fifth-color);
        }
        h3{
          margin-bottom: 1rem;
        }
        p{margin-bottom: 2.5rem;}
      }
            .text-link{
        align-self: center;
                font-size: 1.6rem;
                line-height: 2.2rem;
                font-weight: 700;
                color: var(--accent-color);
                
            }
            &:hover{
                background: var(--accent-color);
                border-color: var(--accent-color);
                .text-link{
                    color: var(--tertiary-color);
                }
            }
        }
    }
}
@media only screen and (max-width: 991px) {
    .content-section03{
        .items{
            flex-wrap: wrap;
            justify-content: center;
            .item{
                flex: none;
                width: 45rem;
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .content-section03{
        .section-heading{
            margin-bottom: 4rem;
        }
        
        .items{
            padding-bottom: 0;
            margin-bottom: .2rem;
            .item{
                width: 100%;
                padding-bottom: 3.7rem;
                > div{
                  p{margin-bottom: 3rem;}
                }
            }
        }
    }
}

.content-section04{    
    .row{
        --bs-gutter-x: 4rem;
    }
    .image{
        width: 50vw;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .text{
        padding-inline-end: 11.5rem;
        .content-margin{
            margin-bottom: 3rem;
        }
    .btn-hold{padding-top: 0.5rem;}
        h3{
            margin-bottom: 3rem;
        }
    }
}
.rtl-page .content-section04 .image{
    right: auto;
    left: 0;
}
.rtl-page .content-section04 .image img{
    transform: scaleX(-1);
}
@media only screen and (max-width: 1200px) {
    .content-section04{
        .text{
            padding-inline-end: 0;
        }
    }
}
@media only screen and (max-width: 991px) {
    .content-section04{
        padding: 0;
        .image{
            width: auto;
            margin: 0 -2rem;
            text-align: center;
            position: relative;
            padding: 0 0 83.33333333333333vw;
        }
        .text{
            padding: 4rem 0;
        }
    }
}
@media only screen and (max-width: 767px) {
    .content-section04{        
        .text{
            .content-margin{margin-bottom: 3rem;}
            h3{margin-bottom: 2rem;}
            p{margin-bottom: 3rem;}
      .btn-hold{padding-top: 0;}
        }
    }
}


.content-section06{
    background: var(--quarter-bg);
    color:var(--quarter-color);
    h2,
    h3{
        color: var(--fifth-color);
    }
    .section-heading{
        max-width: 117rem;
        margin-left: auto;
        margin-right: auto;
    }
}
@media only screen and (max-width: 991px) {
    .content-section06{
        padding: 4rem 0;
        h2{
            margin-bottom: 3rem;
        }
    }
}

.payment {
    background: var(--fifth-bg);
    padding: 2.1rem 0;
    .items {
        display: flex;
        gap: 1.8rem 2.4rem;
        justify-content: space-between;
        align-items: center;
    }
}
@media only screen and (max-width: 767px) {
    .payment {
    padding: 4rem 0;
        .items{
            flex-wrap: wrap;
            justify-content: center;
            gap: 2rem;
            .item{
                margin: 0 -1.1rem -1rem;
                transform: scale(.75);
            }
        }
    }
}

/*bootstrap fixes*/
@media only screen and (min-width: 575px) {
    .text-xs-start {
        text-align: start !important;
    }

    .text-xs-end {
        text-align: end !important;
    }
}
@media only screen and (min-width: 576px) {
    .text-sm-start {
        text-align: start !important;
    }

    .text-sm-end {
        text-align: end !important;
    }
}

@media only screen and (min-width: 768px) {
    .text-md-start {
        text-align: start !important;
    }

    .text-md-end {
        text-align: end !important;
    }
}

@media only screen and (min-width: 992px) {
    .text-lg-start {
        text-align: start !important;
    }

    .text-lg-end {
        text-align: end !important;
    }
}

@media only screen and (min-width: 1200px) {
    .text-xl-start {
        text-align: start !important;
    }

    .text-xl-end {
        text-align: end !important;
    }
}

/* offsets */
.rtl-page *[class*=offset-]{
    margin-left: 0 !important;
}
@media (min-width: 576px) {
    .rtl-page .offset-sm-1 {
        margin-right: 8.333333%
    }

    .rtl-page .offset-sm-2 {
        margin-right: 16.666667%
    }

    .rtl-page .offset-sm-3 {
        margin-right: 25%
    }

    .rtl-page .offset-sm-4 {
        margin-right: 33.333333%
    }

    .rtl-page .offset-sm-5 {
        margin-right: 41.666667%
    }

    .rtl-page .offset-sm-6 {
        margin-right: 50%
    }

    .rtl-page .offset-sm-7 {
        margin-right: 58.333333%
    }

    .rtl-page .offset-sm-8 {
        margin-right: 66.666667%
    }

    .rtl-page .offset-sm-9 {
        margin-right: 75%
    }

    .rtl-page .offset-sm-10 {
        margin-right: 83.333333%
    }

    .rtl-page .offset-sm-11 {
        margin-right: 91.666667%
    }
}

@media (min-width: 768px) {
    .rtl-page .offset-md-1 {
        margin-right: 8.333333%
    }

    .rtl-page .offset-md-2 {
        margin-right: 16.666667%
    }

    .rtl-page .offset-md-3 {
        margin-right: 25%
    }

    .rtl-page .offset-md-4 {
        margin-right: 33.333333%
    }

    .rtl-page .offset-md-5 {
        margin-right: 41.666667%
    }

    .rtl-page .offset-md-6 {
        margin-right: 50%
    }

    .rtl-page .offset-md-7 {
        margin-right: 58.333333%
    }

    .rtl-page .offset-md-8 {
        margin-right: 66.666667%
    }

    .rtl-page .offset-md-9 {
        margin-right: 75%
    }

    .rtl-page .offset-md-10 {
        margin-right: 83.333333%
    }

    .rtl-page .offset-md-11 {
        margin-right: 91.666667%
    }
}

@media (min-width: 992px) {
    .rtl-page .offset-lg-1 {
        margin-right: 8.333333%
    }

    .rtl-page .offset-lg-2 {
        margin-right: 16.666667%
    }

    .rtl-page .offset-lg-3 {
        margin-right: 25%
    }

    .rtl-page .offset-lg-4 {
        margin-right: 33.333333%
    }

    .rtl-page .offset-lg-5 {
        margin-right: 41.666667%
    }

    .rtl-page .offset-lg-6 {
        margin-right: 50%
    }

    .rtl-page .offset-lg-7 {
        margin-right: 58.333333%
    }

    .rtl-page .offset-lg-8 {
        margin-right: 66.666667%
    }

    .rtl-page .offset-lg-9 {
        margin-right: 75%
    }

    .rtl-page .offset-lg-10 {
        margin-right: 83.333333%
    }

    .rtl-page .offset-lg-11 {
        margin-right: 91.666667%
    }
}

@media (min-width: 1200px) {
    .rtl-page .offset-xl-1 {
        margin-right: 8.333333%
    }

    .rtl-page .offset-xl-2 {
        margin-right: 16.666667%
    }

    .rtl-page .offset-xl-3 {
        margin-right: 25%
    }

    .rtl-page .offset-xl-4 {
        margin-right: 33.333333%
    }

    .rtl-page .offset-xl-5 {
        margin-right: 41.666667%
    }

    .rtl-page .offset-xl-6 {
        margin-right: 50%
    }

    .rtl-page .offset-xl-7 {
        margin-right: 58.333333%
    }

    .rtl-page .offset-xl-8 {
        margin-right: 66.666667%
    }

    .rtl-page .offset-xl-9 {
        margin-right: 75%
    }

    .rtl-page .offset-xl-10 {
        margin-right: 83.333333%
    }

    .rtl-page .offset-xl-11 {
        margin-right: 91.666667%
    }
}
@media (min-width: 1400px) {
    .rtl-page .offset-xxl-1 {
        margin-right: 8.333333%
    }

    .rtl-page .offset-xxl-2 {
        margin-right: 16.666667%
    }

    .rtl-page .offset-xxl-3 {
        margin-right: 25%
    }

    .rtl-page .offset-xxl-4 {
        margin-right: 33.333333%
    }

    .rtl-page .offset-xxl-5 {
        margin-right: 41.666667%
    }

    .rtl-page .offset-xxl-6 {
        margin-right: 50%
    }

    .rtl-page .offset-xxl-7 {
        margin-right: 58.333333%
    }

    .rtl-page .offset-xxl-8 {
        margin-right: 66.666667%
    }

    .rtl-page .offset-xxl-9 {
        margin-right: 75%
    }

    .rtl-page .offset-xxl-10 {
        margin-right: 83.333333%
    }

    .rtl-page .offset-xxl-11 {
        margin-right: 91.666667%
    }
}