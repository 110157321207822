@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot?89z6j7');
    src: url('../fonts/icomoon.eot?89z6j7#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?89z6j7') format('truetype'),
        url('../fonts/icomoon.woff?89z6j7') format('woff'),
        url('../fonts/icomoon.svg?89z6j7#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    // font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]>span,
[class*=" icon-"]>span {
    display: none;
}

.icon-brand-fb:before {
	content: "\41";
}

.icon-brand-x:before {
	content: "\42";
}

.icon-brand-ig:before {
	content: "\43";
}

.icon-brand-in:before {
	content: "\44";
}

.icon-brand-yt:before {
	content: "\45";
}

.icon-brand-wa:before {
	content: "\46";
}

.icon-share:before {
	content: "\47";
}

.icon-menu-burger:before {
	content: "\e900";
}

.icon-profile:before {
	content: "\e901";
}

.icon-menu-arrow:before {
	content: "\e902";
}

.icon-list-bullet:before {
	content: "\e903";
}

.icon-cross:before {
	content: "\e904";
}

.icon-plus:before {
	content: "\e905";
}

.icon-minus:before {
	content: "\e906";
}

.icon-link:before {
	content: "\e907";
}

.icon-slider-arrow:before {
	content: "\e908";
}

.icon-pagination-arrow:before {
	content: "\e909";
}

.icon-pagination-arrows:before {
	content: "\e90a";
}

.icon-phone:before {
	content: "\e90b";
}

.icon-email:before {
	content: "\e90c";
}

.icon-location:before {
	content: "\e90d";
}

.icon-funds:before {
	content: "\e90e";
}

.icon-service:before {
	content: "\e90f";
}

.icon-markets:before {
	content: "\e910";
}

.icon-security:before {
	content: "\e911";
}

.icon-support:before {
	content: "\e912";
}

.icon-graph:before {
	content: "\e913";
}

.icon-platform:before {
	content: "\e914";
}

.icon-diagram:before {
	content: "\e915";
}

.icon-graph2:before {
	content: "\e916";
}

.icon-webtrader:before {
	content: "\e917";
}

.icon-mobile:before {
	content: "\e918";
}

.icon-chart:before {
	content: "\e919";
}

.icon-support2:before {
	content: "\e91a";
}

.icon-diagram-window:before {
	content: "\e91b";
}

.icon-diagram-target:before {
	content: "\e91c";
}

.rtl-page .icon-link:before{
    display: inline-block;
    transform: scaleX(-1);
}