
/* buttons */
.button{
	display: inline-flex;
    vertical-align: top;
    justify-content: center;
    align-items: center;
	position: relative;
	padding: 1rem 3rem;
    gap: 1rem;
	// min-width: 17.1rem;
    min-height: 6rem;
	font-family: var(--primary-font-family);    
    line-height: 2rem;
    font-size: 1.6rem;
	font-weight: 700;
	text-decoration: none;
	text-align: center;
    background: var(--button-bg);
    color: var(--button-color);
    border-radius: 3rem;
	&:after,
	&:before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
	&:before {
		border: .1rem solid transparent;
        border-radius: 3rem;
        transition: all 0.3s ease;
	}
	span{
		display: inline-block;
		vertical-align: top;
		position: relative;
		z-index: 1;
	}
    *[class*=icon-]{
        font-size: 1rem;
        //font-weight: 700;
    }
	&:hover,
	&.active{
		background: var(--button-bg-hover);
        color: var(--button-color-hover);
		text-decoration: none;
	}

	&.bordered{
		background: transparent;
		color: var(--button-bg);

		&:before{
			border-color: var(--button-bg);
		}

		&:hover,
		&.active{
			background: var(--button-bg-hover);
			color: var(--button-color-hover);
            &:before{
                border-color: var(--button-bg-hover);
            }
		}
	}

	&.button2 {
		background: var(--button2-bg);
        color: var(--button2-color);
		&:hover,
		&.active{
			background: var(--button2-bg-hover);
            color: var(--button2-color-hover);
		}
		&.bordered{
			background: transparent;
			color: var(--button2-bg);
			&:before{
				border-color: var(--button2-bg);
			}
			&:hover,
			&.active{
				background: var(--button2-bg-hover);
				color: var(--button2-color-hover);
                &:before{
                    border-color: var(--button2-bg-hover);
                }
			}
		}
	}

    &.button3 {
		background: var(--button3-bg);
        color: var(--button-color);
		&:hover,
		&.active{
			background: var(--button3-bg-hover);
            color: var(--button3-color-hover);
		}
		&.bordered{
			background: transparent;
			color: var(--button3-bg);
			&:before{
				border-color: var(--button3-bg);
			}
			&:hover,
			&.active{
				background: var(--button3-bg-hover);
				color: var(--button3-color-hover);
                &:before{
                    border-color: var(--button3-bg-hover);
                }
			}
		}
	}
}

.text-link{
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    // color: var(--seventh-color);
    *[class*=icon-]{
        font-size: 1rem;
    }
    &:hover{
        text-decoration: none;
        //color: var(--tertiary-color);
    }
}
.more {
    display: inline-flex;
    align-items: center;
    gap: 1.2rem;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.2rem;
    text-transform: capitalize;
    *[class*="icon-"] {
        font-size: 1rem;
        font-weight: 700;
    }
    &:hover{text-decoration: none;}
}

.btn-block{
	display: flex;
	width: 100%;
}
.centered-buttons{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 1.5rem;
}

@media (max-width: 575px) {
    .btn-block-xs{width: 100%;}
}
@media (min-width: 575px) and (max-width: 767px) {
	.btn-block-sm{width: 100%;}
}
@media (min-width: 768px) and (max-width: 991px) {
	.btn-block-md{width: 100%;}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.btn-block-lg{width: 100%;}
}
@media (min-width: 1200px) and (max-width: 1399px) {
	.btn-block-xl{width: 100%;}
}
@media (min-width: 1400px){
	.btn-block-xxl{width: 100%;}
}