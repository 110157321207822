::-webkit-scrollbar {
	height: 1rem;
	width: 1rem
  }
  
  ::-webkit-scrollbar-track {
	background-color: var(--primary-bg)
  }
  
  ::-webkit-scrollbar-thumb {
	height: auto;
	background: var(--accent-color);
	width: 1rem
  }
  
  ::selection {
	color: var(--tertiary-color);
	background-color: var(--accent-color);
  }
  
  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, img, ins, kbd, q, s, samp, small, strike, tt, var, b, u, i, center, dl, dt, dd, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
  }
  
  html {
	scrollbar-color: var(--accent-color) var(--primary-bg);
	scrollbar-width: thin;
	font-size: 10px;
  }
  
  .with__bannerSidebar__page {
	.sidebar__holder .title {
	  display: none;
	}
  }
  
  article, aside, details, figcaption, figure,
  footer, header, main, hgroup, menu, nav, section {
	display: block;
  }
  
  blockquote, q {
	quotes: none;
  }
  
  blockquote:before, blockquote:after,
  q:before, q:after {
	content: '';
	content: none;
  }
  
  table {
	border-collapse: collapse;
	border-spacing: 0;
  }
  
  sub, sup {
	position: relative;
	font-size: 65%;
	line-height: 0;
	vertical-align: baseline;
  }
  
  sup {
	top: -0.5em;
  }
  
  sub {
	bottom: -0.25em;
  }
  
  audio,
  canvas,
  video {
	display: inline-block;
  }
  
  audio:not([controls]) {
	display: none;
  }
  
  #map_canvas img, .google-maps img {
	max-width: none;
  }
  
  video, embed, object, iframe {
	display: inline-block;
	vertical-align: top;
	max-width: 100%;
  }
  
  * {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
  }
  
  *:before,
  *:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
  }
  
  fieldset {
	max-width: 100%;
	width: 100%;
	min-width: 100%;
  }
  
  
  body {
	background: var(--main-bg);
	min-width: 36rem;
	font-size: 1.4rem;
	line-height: 200%;
	font-weight: 500;
	font-family: var(--primary-font-family);
	color: var(--main-color);
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	display: flex;
	flex-direction: column;
  }
  
  img {
	opacity: 1;
	transition: opacity 0.3s;
	display: inline-block;
	vertical-align: middle;
	border: 0;
	max-width: 100%;
	height: auto;
	-ms-interpolation-mode: bicubic;
  }
  
  img[data-src] {
	opacity: 0;
  }
  
  a {
	color: var(--accent-color);
	transition: all 0.3s ease;
	text-decoration: none;
  }
  
  a:hover {
	text-decoration: underline;
	color: var(--accent-color);
  }
  
  /* a:active{background-color: transparent;} */
  .tdn {
	text-decoration: none;
  }
  
  .tdu {
	text-decoration: underline;
  }
  
  .tdn:hover {
	text-decoration: underline;
  }
  
  .tdu:hover {
	text-decoration: none;
  }
  
  #wrapper {
	overflow: hidden;
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
  }
  
  .rtl-page #wrapper {
	direction: rtl;
  }
  
  .wrapper__container {
	flex: 1;
	display: flex;
	flex-direction: column;
	position: relative;
  }
  
  .main-holder {
	width: 100%;
	max-width: 145rem;
	margin: 0 auto;
	padding: 0 2rem;
  }
  
  .main-holder .main-holder {
	padding-left: 0;
	padding-right: 0
  }
  
  .main-holder02 {
	max-width: 121rem;
  }
  
  main {
	flex: 1 0 auto;
	position: relative;
  }
  
  .main__container {
	position: relative;
	padding: 8rem 0;
  }
  
  .home__page .main__container {
	padding: 0;
  }
  
  .contactus__page .main__container {
	padding-bottom: 0;
  }
  
  .content-holder {
	flex-direction: row-reverse;
	display: flex;
  }
  
  .content-holder .content {
	flex: 1;
	min-height: .1rem;
  }
  
  #sidebar {
	width: 17rem;
	margin-inline-end: 7rem;
  }
  
  .content-margin {
	margin-bottom: 6rem;
  }
  
  h1, h2, h3, h4, h5, h6 {
	margin: 0;
	color: var(--primary-color);
  }
  
  h1, .h1 {
	font-family: var(--primary-font-family);
	font-size: 4rem;
	line-height: 150%;
	font-weight: 500;
	margin-bottom: 3rem;
  }
  
  h2, .h2 {
	font-family: var(--primary-font-family);
	font-size: 2rem;
	line-height: 150%;
	font-weight: 700;
	margin-bottom: 2rem;
  }
  
  h3, .h3 {
	font-family: var(--primary-font-family);
	font-size: 1.6rem;
	line-height: 150%;
	font-weight: 700;
	margin-bottom: 2rem;
  }
  
  p {
	margin-bottom: 2.8rem;
  }
  
  .fw100{font-weight: 100;}
  .fw200{font-weight: 200;}
  .fw300{font-weight: 300;}
  .fw400{font-weight: 400;}
  .fw500{font-weight: 500;}
  .fw600{font-weight: 600;}
  .fw700{font-weight: 700;}
  .fw800{font-weight: 800;}
  .fw900{font-weight: 900;}
  
  .color01 {
	color: var(--primary-color);
  
	&:hover {
	  color: var(--primary-color);
	}
  }
  
  .color02 {
	color: var(--secondary-color);
  
	&:hover {
	  color: var(--secondary-color);
	}
  }
  
  .color03 {
	color: var(--tertiary-color);
  
	&:hover {
	  color: var(--tertiary-color);
	}
  }
  
  .color04 {
	color: var(--quarter-color);
  
	&:hover {
	  color: var(--quarter-color);
	}
  }
  
  .color05 {
	color: var(--fifth-color);
  
	&:hover {
	  color: var(--fifth-color);
	}
  }
  
  .color06 {
	color: var(--sixth-color);
  
	&:hover {
	  color: var(--sixth-color);
	}
  }
  
  .color07 {
	color: var(--seventh-color);
  
	&:hover {
	  color: var(--seventh-color);
	}
  }
  
  .color08 {
	color: var(--eighth-color);
  
	&:hover {
	  color: var(--eighth-color);
	}
  }
  
  .icon-bg1 {
	background: var(--accent-color);
  }
  
  .icon-bg2 {
	background: var(--accent2-color);
  }
  
  .icon-bg3 {
	background: var(--accent3-color);
  }
  
  
  /* list */
  .list {
	margin: 0 0 3rem;
	padding: 0;
	list-style: none;
  
	li {
	  position: relative;
	  margin: 0 0 2rem;
	  padding-inline-start: 2rem;
  
	  &:before {
		content: "\e905";
		font-family: 'icomoon';
		position: absolute;
		left: 0;
		top: 0;
		top: 50%;
		transform: translateY(-50%);
		font-size: 1rem;
		color: var(--accent-color);
	  }
  
	  ul {
		margin: 1rem 0 0;
		padding: 0;
		list-style: none;
	  }
	}
  }
  
  .rtl-page .list li:before {
	left: auto;
	right: 0;
  }
  
  /* line */
  .line {
	height: .1rem;
	display: block;
	border-top-width: .1rem;
	border-color: var(--main-border-color);
	border-style: solid;
	overflow: hidden;
  
	&.dashed {
	  border-style: dashed;
	}
  
	&.dotted {
	  border-style: dotted;
	}
  }
  
  .social {
	display: flex;
	gap: 1.2rem;
  
	a {
	  display: flex;
	  width: 2rem;
	  height: 1.5rem;
	  justify-content: center;
	  align-items: center;
	  font-size: 1.5rem;
	  line-height: 1.5rem;
	  background: transparent;
	  text-decoration: none;
	  text-align: center;
	  color: var(--seventh-color);
  
	  &:hover {
		text-decoration: none;
		color: var(--quarter-color);
	  }
	}
  }
  
  @media only screen and (max-width: 1200px) {
	.content-holder {
	  display: block;
	}
	#sidebar {
	  display: none;
	}
  
	.home__page .wrapper__container {
	  padding-top: 6rem;
	}
  }
  
  @media only screen and (max-width: 991px) {
  
  }
  
  @media only screen and (max-width: 767px) {
	h1, .h1 {
	  font-size: 3rem;
	  margin-bottom: 2rem;
	}
	.main__container {
	  padding: 4rem 0;
	}
  
	.contactus__page .main__container {
	  padding-bottom: 0;
	}
	.content-margin {
	  margin-bottom: 4rem;
	}
  }
  
  .show-less-575,
  .show-less-767,
  .show-less-991,
  .show-less-1200 {
	display: none;
  }
  
  @media only screen and (max-width: 1200px) {
	.show-less-1200 {
	  display: block
	}
  }
  
  @media only screen and (max-width: 991px) {
	.show-less-991 {
	  display: block
	}
  }
  
  @media only screen and (max-width: 767px) {
	.show-less-767 {
	  display: block
	}
  }
  
  @media only screen and (max-width: 575px) {
	.show-less-575 {
	  display: block
	}
  }